import CustomFieldsActions from '@/components/customfields/CustomFieldsActions';

const rejectResourceRequest = {
	id: 'rejectResourceRequest',
	selectionType: 'single',
	label: 'actions.resourcerequest.rejectResourceRequest',
	functionality: 'ACTION_REJECT_RESOURCE_REQUEST',
	checkAvailability: function (registries) {
		return registries.length > 0 && registries[0].statusid != 'FI' && registries[0].statusid != 'RE';
	},
	runAction: function (action, model, registries) {
		// Sample code to open a model dialog
		const row = registries[0];
		// Get PK for the header
		/*const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;*/
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-rejectResourceRequest-' + model.name + '-show', row);
	}
};

const finishResourceRequest = {
	id: 'finishResourceRequest',
	selectionType: 'single',
	label: 'actions.resourcerequest.finishResourceRequest',
	functionality: 'ACTION_FINISH_RESOURCE_REQUEST',
	checkAvailability: function (registries) {
		return registries.length > 0 && registries[0].statusid != 'FI' && registries[0].statusid != 'RE';
	},
	runAction: function (action, model, registries) {
		// Sample code to open a model dialog
		const row = registries[0];
		// Get PK for the header
		const objectPk = {};
		for (var index in model.columns) {
			const column = model.columns[index];
			if (column.isPk) {
				objectPk[column.name] = registries[0][column.name];
			}
		}
		row.headerPk = objectPk;
		row.isAction = true;
		this.$puiEvents.$emit('pui-modalDialogForm-finishResourceRequest-' + model.name + '-show', row);
	}
};

const optimize = {
	id: 'optimize',
	selectionType: 'multiple',
	label: 'actions.resourcerequest.optimize',
	functionality: 'READ_RESOURCEREQUEST',
	checkAvailability: function (registrie) {
		return true;
	},
	runAction: function (action, model, registries) {
		// Sample code to open a model dialog
		const row = [];

		row.isAction = true;
		row.data = registries;
		this.$puiEvents.$emit('pui-modalDialogForm-optimize-' + model.name + '-show', row);
	}
};

export default {
	gridactions: [rejectResourceRequest, finishResourceRequest, optimize, CustomFieldsActions.configFields('resourcerequest')],
	formactions: [rejectResourceRequest, finishResourceRequest]
};
